import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Heading1, Body1, Body2 } from "components/TextStyles";
import ContactUsForm from "components/forms/ContactUsForm";

// assets
import GreyMattersEmblem from "assets/svg/gmlogos/GMLogoWhite";

export default function Top() {
  return (
    <>
      <Wrapper id="home" className="container">
        <LeftSide>
          <Heading1 marginTop="90px">Contact Us</Heading1>
          <ContactUsForm />
        </LeftSide>
        <RightSide>
          <GreyMattersEmblem />
          <div>
            <Body1 marginBottom="10px">ella@greymatters.com.au</Body1>
            <Body2 fontColor="grey">
              39 Mosman Street
              <br />
              Mosman
              <br />
              NSW Australia 2088
              <br />
              <br />
              ph: +61 404 667 799
            </Body2>
          </div>
        </RightSide>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  margin-bottom: 64px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  order: 2;
  padding-top: 48px;

  @media (max-width: 960px) {
    min-height: 60vh;
    flex-direction: column;
  }

  @media (max-width: 760px) {
    align-items: center;
  }
`;

const LeftSide = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const RightSide = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: clamp(160px, 30vh, 200px);
  padding-left: 80px;

  width: 100%;

  @media (max-width: 960px) {
    padding-top: 60px;
    min-height: 0;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 32px;
    padding-left: 0px;
  }

  @media (max-width: 760px) {
    padding-left: 2.5vw;
  }
`;

export const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50vw;
  gap: 4px;

  @media (max-width: 960px) {
    width: 80vw;
  }
  @media (max-width: 760px) {
    width: 100%;
  }
`;
