import React, { useReducer } from "react";
import styled from "styled-components";

// Components
import { RotatingDropDownArrow } from "components/styledelements/GeneralStyledElements";
import {
  TopNavTabText,
  TopNavTab,
  TopNavTabChild,
} from "components/styledsections/NavBarStyles";
import { SideBarButtonText } from "components/TextStyles";
import Link from "components/elements/ScrollToTopLink";

// Assets
import CloseIcon from "../../assets/svg/icons/CloseIcon";
import LogoIcon from "../../assets/svg/gmlogos/GMLogoSmallWhite";

const initialState = {
  organisationsDropdownOpen: false,
  insightsDropdownOpen: false,
};

function dropdownReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_ORGANISATIONS":
      return {
        ...state,
        organisationsDropdownOpen: !state.organisationsDropdownOpen,
      };
    case "TOGGLE_INSIGHTS":
      return { ...state, insightsDropdownOpen: !state.insightsDropdownOpen };
    default:
      return state;
  }
}

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const [state, dispatch] = useReducer(dropdownReducer, initialState);

  const toggleDropdown = (type) => {
    dispatch({ type: `TOGGLE_${type}` });
  };

  return (
    <Wrapper sidebarOpen={sidebarOpen}>
      <SidebarHeader>
        <LogoIcon />
        <CloseIcon
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="pointer"
        />
      </SidebarHeader>
      <Pages>
        <li>
          <Link to="/">
            <TopNavTabText>Home</TopNavTabText>
          </Link>
        </li>
        <li>
          <Link to="/about">
            <TopNavTabText>About</TopNavTabText>
          </Link>
        </li>
        <li>
          <TopNavTab onClick={() => toggleDropdown("ORGANISATIONS")}>
            <TopNavTabText>Organisations</TopNavTabText>
            <RotatingDropDownArrow
              isActive={state.organisationsDropdownOpen}
              fromDegree="0"
              toDegree="-180"
              transitionSpeed="0.4s"
            />
          </TopNavTab>
          {state.organisationsDropdownOpen && (
            <DropDownMenu isActive={state.organisationsDropdownOpen}>
              <Link to="/organisations/corporations">
                <TopNavTabChild className="hoverHighlight">
                  Corporations
                </TopNavTabChild>
              </Link>
              <Link to="/organisations/clubs">
                <TopNavTabChild className="hoverHighlight">
                  Clubs
                </TopNavTabChild>
              </Link>
              <Link to="/organisations/individuals">
                <TopNavTabChild className="hoverHighlight">
                  Individuals
                </TopNavTabChild>
              </Link>
              <Link to="/organisations/public-sector">
                <TopNavTabChild className="hoverHighlight">
                  Public Sector
                </TopNavTabChild>
              </Link>
            </DropDownMenu>
          )}
        </li>
        <li>
          <TopNavTab onClick={() => toggleDropdown("INSIGHTS")}>
            <TopNavTabText>Insights</TopNavTabText>
            <RotatingDropDownArrow
              isActive={state.insightsDropdownOpen}
              fromDegree="0"
              toDegree="-180"
              transitionSpeed="0.4s"
            />
          </TopNavTab>
          {state.insightsDropdownOpen && (
            <DropDownMenu isActive={state.insightsDropdownOpen}>
              {/* <Link to="/insights/media">
                <TopNavTabChild className="hoverHighlight">
                  Media
                </TopNavTabChild>
              </Link> */}
              <Link to="/insights/resources">
                <TopNavTabChild className="hoverHighlight">
                  Resources
                </TopNavTabChild>
              </Link>
            </DropDownMenu>
          )}
        </li>
        <li>
          <Link to="/greytasker">
            <TopNavTabText>GreyTasker</TopNavTabText>
          </Link>
        </li>
        <li>
          <Link to="/greyacademy">
            <TopNavTabText>GreyAcademy</TopNavTabText>
          </Link>
        </li>
        {/* <li>
          <Link to="/repurpose-quiz">
            <TopNavTabText>
              Re
              <span
                style={{
                  position: "relative",
                  bottom: "4px",
                  padding: "2px",
                }}
              >
                .
              </span>
              purpose Quiz
            </TopNavTabText>
          </Link>
        </li> */}
        <li>
          <Link to="/manifesto">
            <TopNavTabText>Manifesto</TopNavTabText>
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <TopNavTabText>Contact Us</TopNavTabText>
          </Link>
        </li>
      </Pages>
      <SidebarFooter>
        <li className="flexCenter">
          <Link to="/sign-in" className="whiteColor">
            <SideBarButtonText fontColor="var(--white, #FFF)">
              Sign in
            </SideBarButtonText>
          </Link>
        </li>
        <li className="flexCenter">
          <Link
            to="/sign-in"
            className="radius8 lightBg"
            style={{ padding: "10px 15px" }}
          >
            <SideBarButtonText>Get started</SideBarButtonText>
          </Link>
        </li>
      </SidebarFooter>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  transition: 0.3s ease;
  background-color: #12141a;
  border-left: 3px solid #272931;
  width: 400px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 30px 0;
`;

const Pages = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 40px;
  li {
    margin: 20px 0;
  }
`;

const SidebarFooter = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 40px;
  li {
    margin: 20px 0;
  }
`;

const DropDownMenu = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-top: 16px;
`;
