import styled from "styled-components";

export const TopNavTab = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
`;

export const TopNavTabText = styled.section`
  font-family: Inter;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "400"};

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: 140%;
  text-align: ${(props) => props.textAlign || "left"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};

  white-space: nowrap;

  @media (max-width: 1400px) {
    font-size: 1rem;
  }
`;

export const TopNavTabChild = styled.section`
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "400"};

  color: ${(props) =>
    props.isActive ? "rgb(90, 235, 209)" : "var(--White, #FFF)"};
  line-height: 140%;
  text-align: ${(props) => props.textAlign || "left"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};

  &.hoverHighlight {
    padding: 5px 7px;
    border-radius: 9px;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: #272931;
    }
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
  }
`;
