import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Body1, Heading2 } from "components/TextStyles";

export default function AboutUs() {
  return (
    <>
      <Wrapper className="container flexSpaceCenter">
        <AboutUsContent>
          <Heading2>About us</Heading2>
          <Body1>
            We are on a mission to disrupt the antiquated views of ageing. Being
            over 50, we didn't feel ready to retire{" "}
            <span style={{ fontStyle: "italic" }}>
              (etymology: retreat from battle)
            </span>{" "}
            and we certainly didn't feel over the hill. Having seen ageism in
            society and being strong advocates for all diversity, we decided to
            found Grey Matters.
          </Body1>
          {/* <Body1>
            Claire, Co-founder: "I'm a futurist and digital transformation
            disruptor with over 30 years of executive experience in technology
            leadership roles. I love live music gigs, collecting vinyls and
            street art. My favourite books are Pride and Prejudice and Altered
            Carbon."
          </Body1> */}
          <Body1>
            Some things definitely get better with age! Join our platform and be
            part of the revolution.
          </Body1>
        </AboutUsContent>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  @media (max-width: 960px) {
    order: 1;
    margin-top: 30px;
  }
`;

const AboutUsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60vw;
  gap: 32px;
  padding-top: 10vh;

  @media (max-width: 960px) {
    width: 100%;
  }
`;
