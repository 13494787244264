import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { AirQuotesWrapper } from "components/styledsections/GeneralStyledSections";
import { Body1, Body2, Heading3 } from "components/TextStyles";

// assets
import LeftAirQuote from "assets/svg/quotes/LeftAirQuote";
import RightAirQuote from "assets/svg/quotes/RightAirQuote";
import ClaireSignature from "assets/svg/signatures/ClaireSignature";
import JulijaSignature from "assets/svg/signatures/JulijaSignature";
import Julija from "assets/img/aigeneratedimages/julija.png";
import Claire from "assets/img/aigeneratedimages/claire.png";

export default function OurMission() {
  return (
    <>
      <Wrapper className="container flexSpaceCenter">
        <AirQuotesWrapper>
          <LeftAirQuote width={120} />
          <Heading3 textAlign="center">
            Our mission is to redefine and dispel the negative beliefs around
            ageing. Stop retiring and start rewiring.
            <br></br>The time is now.
          </Heading3>
          <RightAirQuote width={120} />
        </AirQuotesWrapper>
        <SignatureWrapper>
          <ClaireSignature />
        </SignatureWrapper>
        <div>
          <Body1 textAlign="center" fontWeight="700">
            Claire
          </Body1>
          <Body1 textAlign="center">Grey Matters Founder</Body1>
        </div>
        <ClaireAndJulija>
          <AIGenerated>
            <Body2>
              <em>AI Generated</em>
            </Body2>
          </AIGenerated>
          <ClaireImg src={Claire} />
        </ClaireAndJulija>
      </Wrapper>
    </>
  );
}

const AIGenerated = styled.section`
  position: absolute;
  bottom: 0px;
  right: 5px;
  z-index: 3;
`;

const Wrapper = styled.section`
  padding-top: calc(40px + 10vh);
  position: relative;
  width: 100%;
  min-height: 100vh;
  gap: calc(23px + 4vh);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SignatureWrapper = styled.section`
  display: flex;

  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0 3vw;
  flex-wrap: wrap;
`;

const ClaireImg = styled.img`
  width: 40vw;
  z-index: 2;
`;

const ClaireAndJulija = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;
