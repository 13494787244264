import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import Top from "./sections/Top";
// import JobList from "./Sections/JobList";
import Footer from "components/common/Footer";

export default function GreyTasker() {
  return (
    <>
      <TopNavbar />
      {/* <JobList /> */}
      <Top />

      <Footer />
    </>
  );
}
