import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Screens
// Main
import Landing from "./screens/landing/Landing.jsx";
import About from "./screens/about/About.jsx";
import GreyTasker from "screens/greytasker/GreyTasker.jsx";
import Quiz from "screens/quiz/Quiz.jsx"
import Contact from "./screens/contact/Contact.jsx";

// Organisations
import Corporations from "screens/organisations/corporations/Corporations.jsx";
import Clubs from "screens/organisations/clubs/Clubs.jsx";
import Individuals from "screens/organisations/individuals/Individuals.jsx";
import PublicSector from "screens/organisations/publicsector/PublicSector.jsx";

// Insights
import Resources from "screens/insights/resources/Resources.jsx";
import Media from "screens/insights/media/Media.jsx";

// Other
import RegisterInterest from "screens/registerinterest/RegisterInterest.jsx"
import Privacy from "./screens/privacy";

import { GMAmplifyInit } from "graphql/GMAmplifyInit.jsx";
import Manifesto from "screens/manifesto/Manifesto.jsx";
import GreyAcademy from "screens/greyacademy/GreyAcademy.jsx";

export default function App() {
  return (
    <>
    <GMAmplifyInit />

    <Router>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/organisations/corporations" element={<Corporations />} />
        <Route path="/organisations/clubs" element={<Clubs />} />
        <Route path="/organisations/individuals" element={<Individuals />} />
        <Route path="/organisations/public-sector" element={<PublicSector />} />
        <Route path="/insights/media" element={<Media />} />
        <Route path="/insights/resources" element={<Resources />} />
        <Route path="/greytasker" element={<GreyTasker />} />
        <Route path="/repurpose-quiz" element={<Quiz />} />
        <Route path="/sign-in" element={<RegisterInterest />} />
        <Route path="/manifesto" element={<Manifesto />} />
        <Route path="/greyacademy" element={<GreyAcademy />} />
      </Routes>
    </Router>
    </>
  );
}
